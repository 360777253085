//== Class definition

var BootstrapDatepicker = function () {

    //== Private functions
    var demos = function () {
        // minimum setup
        // $('.datepicker').datepicker({
        //     todayHighlight: true,
        //     autoClose: true,
        //     // orientation: "bottom left",
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });

        // // minimum setup for modal demo
        // $('#m_datepicker_1_modal').datepicker({
        //     todayHighlight: true,
        //     orientation: "bottom left",
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // input group layout
        // $('#m_datepicker_2, #m_datepicker_2_validate').datepicker({
        //     todayHighlight: true,
        //     orientation: "bottom left",
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // input group layout for modal demo
        // $('#m_datepicker_2_modal').datepicker({
        //     todayHighlight: true,
        //     orientation: "bottom left",
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // enable clear button
        // $('#m_datepicker_3, #m_datepicker_3_validate').datepicker({
        //     todayBtn: "linked",
        //     clearBtn: true,
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // enable clear button for modal demo
        // $('#m_datepicker_3_modal').datepicker({
        //     todayBtn: "linked",
        //     clearBtn: true,
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // orientation
        // $('#m_datepicker_4_1').datepicker({
        //     orientation: "top left",
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // $('#m_datepicker_4_2').datepicker({
        //     orientation: "top right",
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // $('#m_datepicker_4_3').datepicker({
        //     orientation: "bottom left",
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // $('#m_datepicker_4_4').datepicker({
        //     orientation: "bottom right",
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        // // range picker
        // $('#m_datepicker_5').datepicker({
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
        //
        //  // inline picker
        // $('#m_datepicker_6').datepicker({
        //     todayHighlight: true,
        //     templates: {
        //         leftArrow: '<i class="la la-angle-left"></i>',
        //         rightArrow: '<i class="la la-angle-right"></i>'
        //     }
        // });
    }

    return {
        // public functions
        init: function() {
            demos();
        }
    };
}();

jQuery(document).ready(function() {
    console.log($('.datepicker'));
    BootstrapDatepicker.init();
});
